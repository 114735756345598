export const themes = [
    {
        name: "繽紛色",
        headerBackgroundColor: "#b6bbbb",
        headerFontColor: "#000000",
        indexColumnBackgroundColor: "#cad2d2",
        indexColumnFontColor: "#000000",
        mainBackgroundColor: "#ffffff",
        borderColor: "#000000",
        courseFontColor: "#000000",
        courseBackgroundColor: {
            必修: "#c5a6a1",
            體育: "#d8b6a2",
            軍訓: "#aeb4c7",
            外語: "#a1a9a3",
            通識: "#c2cfc6",
            選修: "#e3cda0",
            自訂: "#aebed1",
        },
    },
    {
        name: "自然綠",
        headerBackgroundColor: "#eaeae8",
        headerFontColor: "#000000",
        indexColumnBackgroundColor: "#eaeae8",
        indexColumnFontColor: "#000000",
        mainBackgroundColor: "#ffffff",
        borderColor: "#000000",
        courseFontColor: "#000000",
        courseBackgroundColor: {
            必修: "#dadbc9",
            體育: "#e5ddc6",
            軍訓: "#b6b4bf",
            外語: "#dad2c5",
            通識: "#c1b59d",
            選修: "#b1b3a6",
            自訂: "#a8b7a7",
        },
    },
    {
        name: "煙霧藍",
        headerBackgroundColor: "#b6bbbb",
        headerFontColor: "#000000",
        indexColumnBackgroundColor: "#cad2d2",
        indexColumnFontColor: "#000000",
        mainBackgroundColor: "#f4f4f2",
        borderColor: "#000000",
        courseFontColor: "#000000",
        courseBackgroundColor: {
            必修: "#6f8090",
            體育: "#d0d0c4",
            軍訓: "#acb9bf",
            外語: "#c5c5d1",
            通識: "#c9c0bc",
            選修: "#979aac",
            自訂: "#919faf",
        },
    },
    {
        name: "原野綠",
        headerBackgroundColor: "#e0ddd6",
        headerFontColor: "#000000",
        indexColumnBackgroundColor: "#e0ddd6",
        indexColumnFontColor: "#000000",
        mainBackgroundColor: "#ffffff",
        borderColor: "#4b3728",
        courseFontColor: "#000000",
        courseBackgroundColor: {
            必修: "#8e958e",
            體育: "#e8d8bf",
            軍訓: "#f4ebdc",
            外語: "#babdb4",
            通識: "#cdc0a6",
            選修: "#e0d9bd",
            自訂: "#d3d1c8",
        },
    },
];
